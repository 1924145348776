import React, { useState } from 'react';
import { Card, Carousel, CarouselCaption, CarouselIndicators, CarouselItem, Col, Container, Row } from 'reactstrap';
import { SuccessImageEntity } from './successImageEntity';

export default function SuccessImage(props: { successImages: SuccessImageEntity[] }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const {successImages} = props;

  const onExiting = () => {
    setAnimating(true);
  }

  const onExited = () => {
    setAnimating(false);
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === successImages?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? successImages?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
      <>
        <div className={'section pt-o'} id={'carousel'}>
          <Container>
            <Row>
              <Col className={'ml-auto mr-auto'} md={'8'}>
                <Card className={'page-carousel'}>
                  <Carousel
                      activeIndex={activeIndex}
                      next={next}
                      previous={previous}
                  >
                    <CarouselIndicators
                        items={successImages}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                    />
                    {successImages?.map((image) => {
                      return (
                          <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={image.url}
                          >
                            <img src={image.url} alt={image.name}/>
                            <CarouselCaption
                                captionText={''}
                                captionHeader={<strong>{image.name}</strong>}
                            />
                          </CarouselItem>
                      );
                    })}
                    <a
                        className={'left carousel-control carousel-control-prev'}
                        data-slide={'prev'}
                        href={'#pablo'}
                        onClick={(e) => {
                          e.preventDefault();
                          previous();
                        }}
                        role={'button'}
                    >
                      <span className={'fa fa-angle-left'}/>
                      <span className={'sr-only'}>Previous</span>
                    </a>
                    <a
                        className={'right carousel-control carousel-control-next'}
                        data-slide={'next'}
                        href={'#pablo'}
                        onClick={(e) => {
                          e.preventDefault();
                          next();
                        }}
                        role={'button'}
                    >
                      <span className={'fa fa-angle-right'}/>
                      <span className={'sr-only'}>Next</span>
                    </a>
                  </Carousel>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {' '}
      </>
  )
}