import React from 'react';

import logo from '../assets/img/logoneu.png';
import { Col, Container, Row } from 'reactstrap';

export default function Logo() {
  return (
      <>
        <div className="section">
          <Container className="text-center">
            <Row className="text-center logo-row">
              <Col className="ml-auto mr-auto" md="8">
                <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={logo}
                    style={{width: '100%'}}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
  )
}
