import React from 'react';
import { SuccessStoryEntity } from './successStoryEntity';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';


export default function SuccessStory(props: { successStory: SuccessStoryEntity }) {
  const {successStory} = props;
  const {t} = useTranslation();

  return (
      <div className={'section'}>
        <Container className={'text-center'}>
          <Row>
            <Col className={'ml-auto mr-auto text-center'} md={'8'}>
              <h3 className={'title'}>{successStory?.name}</h3>
            </Col>
          </Row>
          <Row>
            <Col className={'text-center'} md={'6'}>
              <img
                  alt={successStory?.before.name + '_before'}
                  className={'img-rounded img-responsive'}
                  src={successStory?.before.url}
                  style={{width: '50%'}}
              />
              {
                successStory?.startWeight &&
                <h6>{t('successStory.startWeight') + successStory?.startWeight}</h6>
              }

            </Col>
            <Col className={'text-center'} md={'6'}>
              <img
                  alt={successStory?.after.name + '_after'}
                  className={'img-rounded img-responsive'}
                  src={successStory?.after.url}
                  style={{width: '50%'}}
              />
              {
                successStory?.endWeight &&
                <h6>{t('successStory.endWeight') + successStory?.endWeight}</h6>
              }
            </Col>
          </Row>
          {
            successStory?.duration &&
            <Row>
                <Col className={'ml-auto mr-auto text-center'} md={'8'}>
                    <h6>{t('successStory.trainingsDuration') + successStory?.duration}</h6>
                </Col>
            </Row>
          }
          {
            successStory?.description &&
            <Row>
                <Col className={'ml-auto mr-auto text-center text-dark'} md={'8'}>
                    <p>{successStory?.description}</p>
                </Col>
            </Row>
          }
        </Container>
      </div>
  )
}
