import React from 'react';
import { Container } from 'reactstrap';

import header from '../../assets/img/kriss-header.png';

export default function IndexHeader() {
  return (
      <>
        <div
            className="page-header section-dark"
            style={{
              backgroundImage:
                  'url(' + header + ')',
            }}
        >
          <div className="filter"/>
          <div className="content-center">
            <Container>
              <div className="motto text-center">
                <h1><strong className={'text-primary'}>KrisFit</strong> <strong>Nutrition</strong></h1>
                <h3>by Coach Kris</h3>
              </div>
            </Container>
          </div>
        </div>
      </>
  )
}
