import React from 'react';
import { Button, Modal } from 'reactstrap';
import { ProgramEntity } from './programEntity';
import { useTranslation } from 'react-i18next';

export interface ProgramDialogProps {
  program: ProgramEntity
  open: boolean,
  toggleOpen: () => void,
}

export default function ProgramDialog(props: ProgramDialogProps) {
  const {toggleOpen, open, program} = props;
  const {t} = useTranslation();

  return (
      <Modal isOpen={open} toggle={toggleOpen}>
        <div className={'modal-header'}>
          <button
              aria-label={'Close'}
              className={'close'}
              type={'button'}
              onClick={toggleOpen}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5
              className={'modal-title text-center'}
              id={'exampleModalLabel'}
          >
            <strong>{program.title}</strong>
          </h5>
        </div>
        <div className={'modal-body'}>
          {program.description}
          {
            (program.duration || program.content.length > 0) &&
            <>
                <br/>
                <br/>
            </>
          }
          {
            program.duration &&
            <h6>{t('programs.dialog.duration') + program.duration}</h6>
          }
          {
            program.content.length > 0 &&
            <>
                <h6>{t('programs.dialog.content')}</h6>
                <ul>
                  {
                    program.content.map((content, index) => {
                      return (
                          <li key={index}>{content.content}</li>
                      )
                    })
                  }
                </ul>
            </>
          }
        </div>
        <div className={'modal-footer'}>
          <div className={'right-side'}>
            <Button
                className={'btn-link'}
                color={'default'}
                type={'button'}
                onClick={toggleOpen}
            >
              {t('programs.dialog.close')}
            </Button>
          </div>
        </div>
      </Modal>
  )
}
