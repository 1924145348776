import { AboutMeEntity } from './aboutMeEntity';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import React from 'react';


export default function Qualifications(props: { aboutMe: AboutMeEntity }) {
  const {t} = useTranslation();
  const {aboutMe} = props;
  return (
      <>
        <div className={'section'}>
          <Container className={'text-center'}>
            <Row className={'qualification'}>
              <Col className={'ml-auto mr-auto'} md={'8'}>
                <h2 className={'title'}>{t('aboutMe.education.headline')}</h2>
              </Col>
            </Row>
            <Row md={'8'}>
              <Col className={'ml-auto mr-auto'} md={'8'}>
                <div className="divTable">
                  <div className="divTableBody">
                    {
                      aboutMe?.qualifications?.map((qualification, index) =>
                          <div className="divTableRow" key={index + 'row'}>
                            <div className="divTableCell" key={index + 'left'}><span className={'note text-dark'} key={index + 'quali-date'}>{qualification.date}</span></div>
                            <div className={'divTableCell text-dark'} key={index + 'right'}>{qualification.name}</div>
                          </div>
                      )
                    }
                  </div>
                </div>
              </Col>
            </Row>

          </Container>
        </div>
      </>
  )
}
