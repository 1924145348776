import React from 'react';
import { Container, NavLink, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function OkFooter() {
  const {t} = useTranslation();

  return (
      <footer className={'footer footer-black footer-white fixed-bottom'}>
        <Container>
          <Row>
            <nav className={'footer-nav'}>
              <ul>
                <li>
                  <NavLink
                      href={'/imprint'}
                  >
                    {t('footer.imprint')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      href={'/privacyPolicy'}
                  >
                    {t('footer.privacyPolicy')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      href={'/termsOfUse'}
                  >
                    {t('footer.termsOfUse')}
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className={'credits ml-auto'}>
            <span className={'copyright'}>
              {t('footer.copyright')}
            </span>
            </div>
          </Row>
        </Container>
      </footer>
  )
}
