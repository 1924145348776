import React from 'react';
import { ConceptEntity } from './conceptEntity';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

export default function Concept(props: { concept: ConceptEntity }) {
  const {t} = useTranslation();
  const {concept} = props;

  return (
      <div className={'section'} id={'concept'}>
        <Container className={'text-center'}>
          <Row>
            <Col className={'ml-auto mr-auto text-center'} md={'8'}>
              <h2 className={'title'}>{t('concept.headline')}</h2>
            </Col>
          </Row>
          <Row>
            <Col className={'ml-auto mr-auto text-center'} md={'8'}>
              <p className={'description text-dark'}>
                {concept?.content}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
