import React from 'react';
import { useData } from '../../service/useData';
import Concept from '../../components/Concept/Concept';

export default function SectionConcept() {
  const {concept} = useData();
  return (
      <Concept concept={concept}/>
  )
}
