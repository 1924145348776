import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AboutMeEntity } from './aboutMeEntity';

export default function AboutMe(props: {aboutMe: AboutMeEntity}) {
  const {t} = useTranslation();
  const {aboutMe} = props;

  return (
      <div className={'section'} id={'aboutMe'}>
        <Container className={'text-center'}>
          <Row>
            <Col className={'ml-auto mr-auto text-center'} md={'8'}>
              <h2 className={'title'}>{t('aboutMe.me.headline')}</h2>
            </Col>
          </Row>
          <Row>
            <Col className={'text-center'} md={'6'}>
              <img
                  alt={aboutMe?.image?.name || ''}
                  className={'img-rounded img-responsive'}
                  src={aboutMe?.image?.url}
                  style={{width: '100%'}}
              />
            </Col>
            <Col className={'text-center'} md={'6'}>
              <p className={'description text-dark'}>
                {aboutMe?.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
