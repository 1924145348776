import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/css/bootstrap.min.css';
import './assets/scss/paper-kit.scss?v=1.2.0';
import './assets/ok/ok.css?v=1.2.0';
import reportWebVitals from './reportWebVitals';
import { Route, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import AppIndex from './views/AppIndex';
import './i18n';
import { Spinner } from './components/spinner/Spinner';
import Imprint from './views/Imprint';
import { ProvideData } from './service/useData';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsOfUse from './views/TermsOfUse';

const hist = createBrowserHistory();

ReactDOM.render(
    <Suspense fallback={<Spinner/>}>
      <ProvideData>
        <Router history={hist}>
          <Switch>
            <Route path={'/imprint'} component={Imprint}/>
            <Route path={'/privacyPolicy'} component={PrivacyPolicy}/>
            <Route path={'/termsOfUse'} component={TermsOfUse}/>
            <Route path={'/'} component={AppIndex}/>
          </Switch>
        </Router>
      </ProvideData>
    </Suspense>,
    document.getElementById('root')
);

reportWebVitals();
