import React from 'react';
import ImprintNavbar from '../components/Navbars/ImprintNavbar';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../components/Logo';

export default function PrivacyPolicy() {
  const {t} = useTranslation();

  function getSection(headline: string, description: string) {
    return  <Row>
      <Col className={'ml-auto mr-auto'} md={'8'}>
        <h3 className={'title'}>{headline}</h3>
        <p className={'text-dark'}>{description}</p>
        <br/>
        <p className={'text-dark'}>{t('privacyPolicy.firebase1')}</p>
        <br/>
        <p className={'text-dark'}>{t('privacyPolicy.firebase2')}</p>
        <br/>
        <p className={'text-dark'}>{t('privacyPolicy.firebase3')}</p>
      </Col>
    </Row>
  }

  return (
      <>
        <ImprintNavbar/>
        <div className={'main'}>
          <div className={'section'}>
            <Container>
              <Logo/>
              <Row>
                <Col className={'ml-auto mr-auto text-center'} md={'8'}>
                  <h2 className={'title'}>{t('privacyPolicy.headline')}</h2>
                </Col>
              </Row>
              <Row>
                <Col className={'ml-auto mr-auto'} md={'8'}>
                  <p className={'text-dark'}>{t('privacyPolicy.description')} {t('imprint.mailAddress')}</p>
                </Col>
              </Row>
              <Row>
                <Col className={'ml-auto mr-auto'} md={'8'}>
                  <h3 className={'title'}>{t('privacyPolicy.infraAndBackendTitle')}</h3>
                  <p className={'text-dark'}>{t('privacyPolicy.infraAndBackendDescription')}</p>
                  <br/>
                  <p className={'text-dark'}>{t('privacyPolicy.infraAndBackendDescription2')}</p>
                </Col>
              </Row>
              {getSection(t('privacyPolicy.cloudStorageTitle'), t('privacyPolicy.cloudStorageDescription'))}
              {getSection(t('privacyPolicy.hostingTitle'), t('privacyPolicy.hostingDescription'))}
              {getSection(t('privacyPolicy.fireStoreTitle'), t('privacyPolicy.fireStoreDescription'))}
            </Container>
          </div>
        </div>
      </>
  )
}
