import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

export default function ImprintNavbar() {

  return (
      <Navbar className={'fixed-top'} expand={'lg'}>
        <Container>
          <div className={'navbar-translate'}>
            <NavbarBrand
                data-placement={'bottom'}
                href="/"
                title={'KrisFit Nutrition'}
            >
              KrisFit Nutrition
            </NavbarBrand>
          </div>
        </Container>
      </Navbar>
  )
}
