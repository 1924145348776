import { ProgramEntity } from '../components/Programs/programEntity';
import { SuccessStoryEntity } from '../components/SuccessStory/successStoryEntity';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { loadAboutMe, loadConcept, loadPrograms, loadSuccessImages, loadSuccessStories } from './Repository';
import { AboutMeEntity } from '../components/AboutMe/aboutMeEntity';
import { SuccessImageEntity } from '../components/SuccessImage/successImageEntity';
import { ConceptEntity } from '../components/Concept/conceptEntity';

export interface DataContext {
  aboutMe: AboutMeEntity,
  concept: ConceptEntity,
  programs: ProgramEntity[],
  successStories: SuccessStoryEntity[],
  successImages: SuccessImageEntity[]
}

const emptyDataContext: DataContext = {
  aboutMe: {} as AboutMeEntity,
  concept: {} as ConceptEntity,
  programs: [] as ProgramEntity[],
  successStories: [] as SuccessStoryEntity[],
  successImages: [] as SuccessImageEntity[]
}

const dataContext = createContext(emptyDataContext);

export function ProvideData(props: { children: ReactNode }) {
  const context = useProvideData();

  return (
      <dataContext.Provider value={context}>
        {props.children}
      </dataContext.Provider>
  )
}

export const useData = () => {
  return useContext(dataContext);
}

function useProvideData(): DataContext {
  const [aboutMe, setAboutMe] = useState<AboutMeEntity>({} as AboutMeEntity)
  const [concept, setConcept] = useState<ConceptEntity>({} as ConceptEntity)
  const [programs, setPrograms] = useState<ProgramEntity[]>([])
  const [successStories, setSuccessStories] = useState<SuccessStoryEntity[]>([])
  const [successImages, setSuccessImages] = useState<SuccessImageEntity[]>([])

  function reloadAboutMe() {
    loadAboutMe().then((data) => {
      setAboutMe(data)
    })
  }

  function reloadConcept() {
    loadConcept().then((data) => {
      setConcept(data)
    })
  }

  function reloadPrograms() {
    loadPrograms().then((data) => {
      setPrograms(data);
    })
  }

  function reloadSuccessStories() {
    loadSuccessStories().then((data) => {
      setSuccessStories(data);
    })
  }

  function reloadSuccessImages() {
    loadSuccessImages().then((data) => {
      setSuccessImages(data);
    })
  }

  useEffect(() => {
    const ac = new AbortController();

    reloadPrograms();
    reloadSuccessStories();
    reloadSuccessImages();
    reloadAboutMe();
    reloadConcept();

    return () => ac.abort();
  }, [])

  return {
    aboutMe: aboutMe,
    concept: concept,
    programs: programs,
    successStories: successStories,
    successImages: successImages
  }
}
