import React, { useEffect } from 'react';
import IndexNavbar from '../components/Navbars/IndexNavbar';
import IndexHeader from '../components/Headers/IndexHeader';
import SectionAboutMe from './appIndex-sections/SectionAboutMe';
import SectionSuccessStories from './appIndex-sections/SectionSuccessStories';
import Logo from '../components/Logo';
import SectionQualification from './appIndex-sections/SectionQualification';
import SectionPrograms from './appIndex-sections/SectionPrograms';
import SectionContact from './appIndex-sections/SectionContact';
import OkFooter from '../components/Footers/OkFooter';
import SectionConcept from './appIndex-sections/SectionConcept';

export default function AppIndex() {
  document.documentElement.classList.remove('nav-open');
  useEffect(() => {
    document.body.classList.add('index');
    return function cleanup() {
      document.body.classList.remove('index');
    };
  });

  return (
      <>
        <IndexNavbar/>
        <IndexHeader/>
        <div className={'main'}>
          <SectionAboutMe/>
          <SectionQualification/>
          <SectionConcept/>
          <Logo/>
          <SectionSuccessStories/>
          <Logo/>
          <SectionPrograms/>
          <SectionContact/>
          <OkFooter/>
        </div>
      </>
  )
}
