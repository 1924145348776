import React from 'react';
import ImprintNavbar from '../components/Navbars/ImprintNavbar';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../components/Logo';

export default function Imprint() {
  const {t} = useTranslation();

  return (
      <>
        <ImprintNavbar/>
        <div className={'main'}>
          <div className={'section'}>
            <Container>
              <Row>
                <Col className={'ml-auto mr-auto text-center'} md={'8'}>
                  <h2 className={'title'}>{t('imprint.headline')}</h2>
                </Col>
              </Row>
              <Row>
                <Col className={'ml-auto mr-auto'} md={'8'}>
                  <h6>{t('imprint.name')}</h6>
                  <p className={'text-dark'}>{t('imprint.ceo')}</p>
                  <p className={'text-dark'}>{t('imprint.street')}</p>
                  <p className={'text-dark'}>{t('imprint.zipCodeAndCity')}</p>
                  <p className={'text-dark'}>{t('imprint.country')}</p>
                  <br/>
                  <p className={'text-dark'}>{t('imprint.mobile')} {t('imprint.mobileNumber')}</p>
                  <p className={'text-dark'}>{t('imprint.mail')} {t('imprint.mailAddress')}</p>
                  <br/>

                  <p className={'text-dark'}>{t('imprint.responsible')}</p>
                  <p className={'text-dark'}>{t('imprint.responsiblePerson')}</p>
                  <p className={'text-dark'}>{t('imprint.questions')}</p>
                  <p className={'text-dark'}>{t('imprint.mailAddress')}</p>
                </Col>
              </Row>
              <Logo/>
            </Container>
          </div>
        </div>
      </>
  )
}
