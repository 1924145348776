import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import emailkey from '../../service/emailkey'
import emailjs from 'emailjs-com';

interface Contact {
  name: string;
  email: string;
  message: string;
}

const emptyContact = {
  name: '',
  email: '',
  message: ''
}

export default function SectionContact() {
  const {t} = useTranslation();
  const [contact, setContact] = useState<Contact>(emptyContact);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(true);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 5000)
    }

    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 5000)
    }
  }, [error, success])

  function onClick() {
    const template = {
      'name': contact.name,
      'email': contact.email,
      'message': contact.message
    };

    emailjs.send(emailkey.SERVICE_ID, emailkey.TEMPLATE_ID, template, emailkey.USER_ID)
        .then((result) => {
          console.log(result.text);
          setContact(emptyContact)
          setSuccess(true)
        }, (error) => {
          console.log(error.text);
          setError(true)
        });

  }

  function onClose() {
    setSuccess(false);
    setError(false);
  }

  // function onVerify(response: any) {
  //   setVerified(true);
  // }

  function isButtonEnabled() {
    return contact.name.length > 0 &&
        contact.email.length > 0 &&
        contact.message.length > 0 &&
        verified
  }

  return (
      <div className={'section'} id={'contact'}>

        <Alert color="success" isOpen={success}>
          <Container>
            <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => onClose()}
            >
              <p>{t('contact.close')}</p>
            </button>
            <span>{t('contact.success')}</span>
          </Container>
        </Alert>

        <Alert className="alert-with-icon" color="danger" isOpen={error}>
          <Container>
            <div className="alert-wrapper">
              <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => onClose()}
              >
                <p>{t('contact.close')}</p>
              </button>
              <span>{t('contact.error')} {t('imprint.mailAddress')}</span>
            </div>
          </Container>
        </Alert>
        <Container>
          <Row>
            <Col className={'ml-auto mr-auto'} md={'8'}>
              <h2 className={'text-center'}>{t('contact.headline')}</h2>
              <Form className={'contact-form'} onSubmit={onClick}>
                <Row>
                  <Col md={'6'}>
                    <label>{t('contact.name')}</label>
                    <Input
                        placeholder={t('contact.name')}
                        type={'text'}
                        required={true}
                        value={contact.name}
                        onChange={event => {
                          setContact(prev => ({
                            ...prev,
                            name: event.target.value
                          }));
                        }}
                    />
                  </Col>
                  <Col md={'6'}>
                    <label>{t('contact.email')}</label>
                    <Input
                        placeholder={t('contact.email')}
                        type={'email'}
                        required={true}
                        value={contact.email}
                        onChange={event => {
                          setContact(prev => ({
                            ...prev,
                            email: event.target.value
                          }));
                        }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>{t('contact.message')}</label>
                    <Input
                        placeholder={t('contact.messagePlaceholder')}
                        type={'textarea'}
                        rows={'4'}
                        required={true}
                        value={contact.message}
                        onChange={event => {
                          setContact(prev => ({
                            ...prev,
                            message: event.target.value
                          }));
                        }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className={'ml-auto mr-auto'} md={'6'}>
                    <br/>
                    {/*<Reaptcha sitekey={'6LdsyUQcAAAAAE7p_43_kPWDtSFNWDOmKSmp04Ln'}*/}
                    {/*          onVerify={onVerify}/>*/}
                  </Col>
                </Row>
                <Row>
                  <Col className={'ml-auto mr-auto'} md={'4'}>
                    <Button
                        disabled={!isButtonEnabled()}
                        onClick={onClick}
                        className={'btn-fill'}
                        color={'primary'}
                        size={'lg'}
                    >
                      {t('contact.send')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
