import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

export const APP: firebase.app.App = initFirebase();
export const storage = APP.storage();
export const firestore = initFireStore();

function initFirebase() {
  return  firebase.initializeApp({
    apiKey: "AIzaSyCU3mqxm6DJyXgCRh7Yc1NYjJZo4mG95PU",
    authDomain: "ok-nutrition.firebaseapp.com",
    projectId: "ok-nutrition",
    storageBucket: "ok-nutrition.appspot.com",
    messagingSenderId: "958066064798",
    appId: "1:958066064798:web:3e0b0327fda6bc18ed2a8f"
  });
}

function initFireStore() {
  let _firestore = APP.firestore();

  _firestore.enablePersistence({synchronizeTabs:true})
  return _firestore
}