import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardTitle } from 'reactstrap';
import { ProgramEntity } from './programEntity';
import ProgramDialog from './ProgramDialog';
import { useTranslation } from 'react-i18next';

export default function Program(props: { program: ProgramEntity }) {
  const [open, setOpen] = useState(false);
  const {program} = props;
  const {t} = useTranslation();

  function toggleOpen() {
    setOpen(!open);
  }

  return (
      <>
        <Card className={'card-program ml-auto mr-auto'}>
          <CardTitle>
            <h3 className={'card-title mx-auto'}><strong>{program.title}</strong></h3>
          </CardTitle>
          <CardBody>
            {program.image &&
              <img
                  alt={program.title || ''}
                  className={'img-responsive'}
                  src={program.image.url}
                  style={{width: '100%'}}
              />
            }

            <h6>{program.duration}</h6>
            <p>{program.description}</p>
          </CardBody>
          <CardFooter>
            <Button block={true} className={'btn-round'} color={'primary'} onClick={toggleOpen}>
              {t('programs.more')}
            </Button>
          </CardFooter>
        </Card>
        <ProgramDialog open={open} toggleOpen={toggleOpen} program={program}/>
      </>
  )
}
