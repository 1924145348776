import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Program from '../../components/Programs/Program';
import { useData } from '../../service/useData';

export default function SectionPrograms() {
  const {t} = useTranslation();
  const {programs} = useData();

  return (
      <>
        <div className={'section'} id={'programs'}>
          <Container className={'text-center'}>
            <Row>
              <Col className={'ml-auto mr-auto text-center'} md={'8'}>
                <h2 className={'title'}>{t('programs.headline')}</h2>
              </Col>
            </Row>
            <Row>
              <Col className={'ml-auto mr-auto'} md={'8'}>
                <p className={'description text-dark'}>
                  {t('programs.description')}
                </p>
              </Col>
            </Row>
            <Row>
              {
                programs?.map((program) => {
                  return (
                      <Program key={program.title} program={program}/>
                  )
                })
              }
            </Row>
          </Container>
        </div>
      </>
  )
}
