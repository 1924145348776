import { ProgramEntity } from '../components/Programs/programEntity';
import { firestore } from './Firebase';
import { SuccessStoryEntity } from '../components/SuccessStory/successStoryEntity';
import { AboutMeEntity } from '../components/AboutMe/aboutMeEntity';
import { SuccessImageEntity } from '../components/SuccessImage/successImageEntity';
import { ConceptEntity } from '../components/Concept/conceptEntity';

const PROGRAM_COLLECTION_PATH = 'programs';
const CONCEPT_COLLECTION_PATH = 'concept';
const SUCCESS_STORIES_COLLECTION_PATH = 'successStories';
const SUCCESS_IMAGES_COLLECTION_PATH = 'successImages';
const ABOUT_ME_COLLECTION_PATH = 'aboutMe';

export async function loadAboutMe(): Promise<AboutMeEntity> {
  const aboutMeTypes = [] as AboutMeEntity[];

  const querySnapshot = await firestore.collection(ABOUT_ME_COLLECTION_PATH).get();
  querySnapshot.forEach(aboutMeSnapshot => {
    if (aboutMeSnapshot.exists) {
      const snapshotData = aboutMeSnapshot.data();
      aboutMeTypes.push({id: aboutMeSnapshot.id, ...snapshotData} as AboutMeEntity)
    }
  })

  return Promise.resolve(aboutMeTypes[0])
}

export async function loadConcept(): Promise<ConceptEntity> {
  const conceptTypes = [] as ConceptEntity[];

  const querySnapshot = await firestore.collection(CONCEPT_COLLECTION_PATH).get();
  querySnapshot.forEach(conceptSnapshot => {
    if (conceptSnapshot.exists) {
      const snapshotData = conceptSnapshot.data();
      conceptTypes.push({id: conceptSnapshot.id, ...snapshotData} as ConceptEntity)
    }
  })

  return Promise.resolve(conceptTypes[0])
}

export async function loadPrograms(): Promise<ProgramEntity[]> {
  const programs = [] as ProgramEntity[];

  const querySnapshot = await firestore.collection(PROGRAM_COLLECTION_PATH).get();
  querySnapshot.forEach(programSnapshot => {
    if (programSnapshot.exists) {
      const snapshotData = programSnapshot.data();
      programs.push({id: programSnapshot.id, ...snapshotData} as ProgramEntity)
    }
  })

  return Promise.resolve(programs)
}

export async function loadSuccessImages(): Promise<SuccessImageEntity[]> {
  const images = [] as SuccessImageEntity[];

  const querySnapshot = await firestore.collection(SUCCESS_IMAGES_COLLECTION_PATH).get();
  querySnapshot.forEach(imageSnapshot => {
    if (imageSnapshot.exists) {
      const snapshotData = imageSnapshot.data();
      images.push({id: imageSnapshot.id, ...snapshotData} as SuccessImageEntity)
    }
  })

  return Promise.resolve(images)
}

export async function loadSuccessStories(): Promise<SuccessStoryEntity[]> {
  const stories = [] as SuccessStoryEntity[];

  const querySnapshot = await firestore.collection(SUCCESS_STORIES_COLLECTION_PATH).get();
  querySnapshot.forEach(storySnapshot => {
    if (storySnapshot.exists) {
      const snapshotData = storySnapshot.data();
      stories.push({id: storySnapshot.id, ...snapshotData} as SuccessStoryEntity)
    }
  })

  return Promise.resolve(stories)
}
