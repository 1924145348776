import React from 'react';
import ImprintNavbar from '../components/Navbars/ImprintNavbar';
import { Col, Container, NavLink, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../components/Logo';

export default function TermsOfUse() {
  const {t} = useTranslation();

  function getSection(index: number, headline: string, description: string) {
    return <Row key={index}>
      <Col className={'ml-auto mr-auto'} md={'8'} key={index}>
        <h3 key={index} className={'title'}>{headline}</h3>
        <p key={index} className={'text-dark'}>{description}</p>
      </Col>
    </Row>
  }

  return (
      <>
        <ImprintNavbar/>
        <div className={'main'}>
          <div className={'section'}>
            <Container>
              <Logo/>
              <Row>
                <Col className={'ml-auto mr-auto text-center'} md={'8'}>
                  <h2 className={'title'}>{t('termsOfUse.headline')}</h2>
                </Col>
              </Row>
              {[...Array(10)].map((x, i) =>
                  getSection(x, t('termsOfUse.p' + (i + 1) + '.headline'), t('termsOfUse.p' + (i + 1) + '.content'))
              )}
              <Row>
                <Col className={'ml-auto mr-auto text-right'} md={'8'}>
                  <br/>
                  <NavLink
                      data-placement={'bottom'}
                      href="/docs/AGB.pdf"
                      target="_blank"
                      title={t('termsOfUse.download')}
                  >
                    <p className={'text-dark font-weight-bold'}>{t('termsOfUse.download')}</p>
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
  )
}
