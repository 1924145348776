import React, { useEffect, useState } from 'react';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { Link, animateScroll as scroll } from 'react-scroll/modules';
import { useTranslation } from 'react-i18next';

export default function IndexNavbar() {
  const [navbarColor, setNavbarColor] = useState('navbar-transparent');
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const {t} = useTranslation();

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
          document.documentElement.scrollTop > 299 ||
          document.body.scrollTop > 299
      ) {
        setNavbarColor('');
      } else if (
          document.documentElement.scrollTop < 300 ||
          document.body.scrollTop < 300
      ) {
        setNavbarColor('navbar-transparent');
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
      <Navbar className={classNames('fixed-top', navbarColor)} expand={'lg'}>
        <Container>
          <div className={'navbar-translate'}>
            <Link
                onClick={() => {
                  scroll.scrollToTop()
                }}
                to={'top'}
                smooth={true}
                spy={true}>
              <NavbarBrand
                  data-placement={'bottom'}
                  title={'KrisFit Nutrition'}
                  href={''}
              >
                {t('navigation.name')}
              </NavbarBrand>
            </Link>
            <button
                aria-expanded={navbarCollapse}
                className={classNames('navbar-toggler navbar-toggler', {
                  toggled: navbarCollapse,
                })}
                onClick={toggleNavbarCollapse}
            >
              <span className={'navbar-toggler-bar bar1'}/>
              <span className={'navbar-toggler-bar bar2'}/>
              <span className={'navbar-toggler-bar bar3'}/>
            </button>
          </div>
          <Collapse
              className={'justify-content-end'}
              navbar
              isOpen={navbarCollapse}
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                    data-placement={'bottom'}
                    href="https://www.facebook.com/kris.ottnutritioncoach.5"
                    target="_blank"
                    title={t('navigation.likeUs')}
                >
                  {t('navigation.facebook')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    data-placement={'bottom'}
                    href="https://www.instagram.com/kris_ott_nutritioncoach/"
                    target="_blank"
                    title={t('navigation.followUs')}
                >
                    {t('navigation.instagram')}
                </NavLink>
              </NavItem>
              <NavItem>
                <Link to={'aboutMe'} smooth={true} spy={true}>
                  <NavLink
                      href={''}
                  >
                    {t('navigation.aboutMe')}
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to={'success'} smooth={true} spy={true}>
                  <NavLink
                      href={''}
                  >
                    {t('navigation.success')}
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to={'programs'} smooth={true} spy={true}>
                  <NavLink href={''}>
                    {t('navigation.programs')}
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to={'contact'} smooth={true} spy={true}>
                  <NavLink href={''}>
                    {t('navigation.contact')}
                  </NavLink>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
  )
}
