import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import SuccessImage from '../../components/SuccessImage/SuccessImage';
import SuccessStory from '../../components/SuccessStory/SuccessStory';
import { useData } from '../../service/useData';

export default function SectionSuccessStories() {
  const {t} = useTranslation();
  const {successStories, successImages} = useData()
  return (
      <>
        <div className={'section'}  id={'success'}>
          <Container className={'text-center'}>
            <Row>
              <Col className={'ml-auto mr-auto text-center'} md={'8'}>
                <h2 className={'title'}>{t('successStory.headline')}</h2>
              </Col>
            </Row>
            <Row>
              <SuccessImage successImages={successImages}/>
            </Row>
            <Row>
              <SuccessStory successStory={successStories[0]}/>
              <SuccessStory successStory={successStories[1]}/>
            </Row>
          </Container>
        </div>
      </>
  )
}